
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { Notification } from "@/models/notification.interface";
import { PenaltyUser } from "@/models/penalty.interface";
import PenaltyCard from "@/components/Layout/Common/PenaltyCard.vue";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: { PenaltyCard, Loader, Empty },
})
export default class ClientPenalties extends mixins(Navigation, StyleCheck) {
  loader = false;
  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }
  pageSize = 5;
  pagination = {
    itemsPerPage: this.pageSize,
    totalItems: 0,
    page: 1,
  };
  changePage() {
    this.$router.push({
      query: { redirection: "/profile", header: "t" },
      path: `/user/penalties/${this.pagination.page}`,
    });
  }
  private async getPenalties(page: string, size: number): Promise<void> {
    const user = this.$store.getters["authentication/getUser"];
    await this.$store
      .dispatch("penalties/getClientPenalties", {
        page,
        size,
        client: user.id,
      })
      .then(() => {
        window.scrollTo(0, 0);
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Penalties.fetchError.Penalties"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    window.scrollTo(0, 0);
    await this.getPenalties(
      this.$route.params.page,
      this.pagination.itemsPerPage
    );
    this.loader = false;
  }

  get penalties(): PenaltyUser[] {
    let response = this.$store.getters["penalties/getPenalties"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);
    if (response?.penalties) {
      return response.penalties.filter(
        (pen: PenaltyUser) => pen.status?.name !== this.$constants.STATUS.ACTIVE
      );
    } else {
      return [];
    }
  }
  get activePenalties(): PenaltyUser[] {
    let response = this.$store.getters["penalties/getPenalties"];

    if (response?.penalties) {
      return response.penalties.filter(
        (penalty: PenaltyUser) =>
          penalty.status?.name == this.$constants.STATUS.ACTIVE
      );
    } else {
      return [];
    }
  }
  get totalPages() {
    const total = this.pagination.totalItems / this.pageSize;
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }
  @Watch("$route.params", { deep: true })
  private async handler() {
    this.loader = true;
    await this.getPenalties(
      this.$route.params.page,
      this.pagination.itemsPerPage
    );
    this.loader = false;
  }
}
